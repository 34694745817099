import { ROUTES } from './routes/translations'

export const getCMSGeneralContent = async (locale) => {
  const CMSApi = process.env.NEXT_PUBLIC_API_CMS
  const resHeader = await fetch(`${CMSApi}/pages?slug=header&acf_format=standard&lang=${locale}`)
  const header = await resHeader.json()
  const resFooter = await fetch(`${CMSApi}/pages?slug=footer&acf_format=standard&lang=${locale}`)
  const footer = await resFooter.json()

  return {
    header: header[0]?.acf,
    footer: footer[0]?.acf
  }
}

export const getCMSContent = async (defaultLocale, locale, page) => {
  const CMSApi = process.env.NEXT_PUBLIC_API_CMS
  const slug = ROUTES[page][defaultLocale || 'es']
  console.log(`${CMSApi}/pages?slug=${slug}&acf_format=standard&lang=${locale}`)
  const res = await fetch(`${CMSApi}/pages?slug=${slug}&acf_format=standard&lang=${locale}`)
  const [data] = await res.json()
  const { header, footer } = await getCMSGeneralContent(locale)

  return {
    seo: data?.yoast_head_json,
    content: data?.acf,
    header,
    footer
  }
}

export const getDynamicCMSContent = async (locale, slug, id) => {
  const CMSApi = process.env.NEXT_PUBLIC_API_CMS
  const res = await fetch(`${CMSApi}/${slug}?slug=${id}&acf_format=standard&lang=${locale}`)
  const [data] = await res.json()
  const { header, footer } = await getCMSGeneralContent(locale)

  return {
    seo: data?.yoast_head_json,
    content: data?.acf,
    header,
    footer
  }
}
